import React, {useRef, useState} from 'react';
import './Footer.css';
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import logo from '../../assets/images/top_white.png';
import iso from '../../assets/images/iso.png';

const Footer = () => {
  const email = useRef(null);
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('Email', email.current.value);
   

    var url = "https://script.google.com/macros/s/AKfycbx7G8KmAP-ShC6btXjnAIsFUH94Zf3iPpXUxIL1181vU3E94rwe4NgqTt9HiYiPDvHg/exec";
    const res = await fetch(url, {
      method: "POST",
      body: formData
    });
    if(res.ok){
      setSuccess(true);
    }else{
      setErr(false);
    }
  }

  return (
    <>
      <footer>
        <Container fluid className='p-0'>
          <Container className='pt-5'>
            <Row>
              <Col md={4} lg={4} sm={12}>
                <img src={logo} height={60} alt='HSR Hi-Tech Solutions' />
                <p className='my-4 text-sm text-offwhite'>As an integral unit of HSR Hi-Tech Solutions, the HSR Skills Lab is dedicated to nurturing the next generation of tech enthusiasts. Providing specialized IT and programming training, the Skills Lab is the crucible where talent is honed, skills are sharpened, and future industry leaders are forged.</p>

                <img className='mt-3 d-block m-auto mb-4' src={iso} alt="ISO Certified" height={100} />
              </Col>
              <Col md={2} lg={2} sm={12}>
                <h5>Important</h5>
                <ul className='mt-4'>
                  <li><a href="/">Home</a></li>
                  <li><a href="/about">About Us</a></li>
                  <li><a href="/courses">Our Courses</a></li>
                  {/* <li><a href="/blogs">Blogs</a></li> */}
                  <li><a href="/faqs">FAQs</a></li>
                  <li><a href="/contact">Contact Us</a></li>
                </ul>
              </Col>
              <Col md={2} lg={2} sm={12}>
                <h5>Company</h5>
                <ul className='mt-4'>
                  <li><a href="/disclaimer">Disclaimer</a></li>
                  <li><a href="/privacy-policy">Privacy Policy</a></li>
                  <li><a href="/terms-and-conditions">Terms & Conditions</a></li>
                  <li><a href="/refund-and-cancellation">Refund & Cancellation</a></li>
                  <li><a href="https://t.me/hsrskillslab" target="_blank">Our Community</a></li>
                </ul>
              </Col>
              <Col md={4} lg={4} sm={12}>
                <h5>Newsletter</h5>
                <Form onSubmit={handleSubmit}>
                <InputGroup className="mb-3 mt-4">
                  <Form.Control required
                  ref={email}
                  placeholder='Your Email Address'
                  />
                  <Button className=' btn-danger' type="submit">
                    Subscribe
                  </Button>
                </InputGroup>
                </Form>
                <div className='pt-3'>
                  {success ? <div className='alert alert-success text-sm'>Thank You for subscribing to our newsletter.</div> : ''}
                  {err ? <div className='alert alert-danger text-sm'>Sorry! Some error occurred. Please try again!</div> : ''}
                </div>
                <p className='text-sm text-offwhite'>Subscribe to our newsletter & get the latest news and updates
                  right at your inbox.</p>
                  <p className='mt-3 text-offwhite'>Follow Us:</p>

              <a href="https://www.facebook.com/hsrskillslab/" target='_blank'><i className='bi bi-facebook me-3 text-offwhite'></i></a>
              <a href="https://www.instagram.com/hsrskillslab/" target='_blank'><i className='bi bi-instagram me-3 text-offwhite'></i></a>
              <a href="https://in.linkedin.com/company/hsrskillslab/" target='_blank'><i className='bi bi-linkedin me-3 text-offwhite'></i></a>
              </Col>
            </Row>
            <hr className='hsr-footer-divider' />
          </Container>
          <div className='footer-copyright text-offwhite text-sm text-center pb-3'><i className='bi bi-c-circle'></i> Copyright 2023: All rights reserved to HSR Skills Lab | A unit of <a className=' text-offwhite' target='_blank' href="https://www.hsrsolutions.co.in/" rel="noreferrer">HSR Hi-Tech Solutions</a></div>
        </Container>
      </footer>
    </>
  )
}

export default Footer