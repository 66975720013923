import React from 'react';
import './Courses.css';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'react-bootstrap';
import image1 from '../../assets/images/slide-1.png';
import image2 from '../../assets/images/slide-2.png';
import image3 from '../../assets/images/slide-3.png';
import image4 from '../../assets/images/slide-4.png';
import image5 from '../../assets/images/slide-5.png';
import CourseCard from '../../components/CourseCard/CourseCard';
import OrangeButton from '../../components/OrangeButton/OrangeButton';

const Courses = () => {
  const data = [
    {
      course_title: 'Programming Fundamentals',
      course_duration: '1 Month',
      course_price: '₹ 1499',
      total_price: '₹ 2499',
      image: image1,
      description: 'Unlock the world of coding with our foundational programming course. Master key languages like Python, Java, and C for a solid programming foundation.',
      url: 'course/programming-fundamentals',
    },
    {
      course_title: 'Full Stack Web Development',
      course_duration: '9-12 Months',
      course_price: '₹ 8999',
      total_price: '₹ 17998',
      image: image2,
      description: 'Journey from front-end to back-end to databases mastery. Learn different technologies to create seamless and dynamic web applications.',
      url: '/course/full-stack-web-development'
    },
    {
      course_title: 'MERN Stack Web Development',
      course_duration: '3-4 Months',
      course_price: '₹ 4499',
      total_price: '₹ 7499',
      image: image3,
      description: "Dive deep into MongoDB, Express.js, React.js, and Node.js. Build modern, scalable web applications with a focus on the MERN stack's powerful capabilities.",
      url: '/course/mern-stack-web-development'
    },
    {
      course_title: 'Data Science and Analytics using Python',
      course_duration: '3-4 Months',
      course_price: '₹ 4499',
      total_price: '₹ 7499',
      image: image4,
      description: "Uncover actionable insights from data using Python. Explore data analysis and visualization techniques to make data-driven decisions.",
      url: '/course/data-science-and-analytics-using-python'
    },
    {
      course_title: 'Machine Learning using Python',
      course_duration: '6-8 Months',
      course_price: '₹ 7199',
      total_price: '₹ 11999',
      image: image5,
      description: "Step into the realm of artificial intelligence. Learn machine learning algorithms, model training, and deployment using Python for intelligent solutions.",
      url: '/course/machine-learning-using-python'
    }
  ]
  return (
    <>
      <Helmet>
      <title>Our Courses - HSR Skills Lab</title>
      <meta name="description" content="Explore a diverse range of courses at HSR Skills Lab - from programming fundamentals to full-stack web development, MERN stack, data science, and machine learning. Elevate your skills with us!" />
    </Helmet>
      <Container fluid className='hsr-page-title'>
        <Container>
          <div className='hsr-section-heading py-5'>
            <h1 className='text-white'>Our Courses</h1>
            <div className='d-flex align-items-center justify-content-center'>
              <span className='hsr-divider bg-white'></span>
              <i className='bi bi-laptop mx-3 hsr-separator'></i>
              <span className='hsr-divider bg-white'></span>
            </div>
            <div className='text-center mt-3'>
              <a href="/" className='text-white'>Home</a>
              <span className='mx-2 text-offwhite'>/</span>
              <span className='text-offwhite'>Our Courses</span>
            </div>
          </div>
        </Container>
      </Container>
      <Container>
        <div className='my-5'>
          <Row className='gy-5 mb-5'>
            {data.map((d) => {
              return (
                <Col lg={4} md={4} sm={12}>
                  <CourseCard item={d} />
                </Col>
              )
            })}
          </Row>
        </div>
      </Container>
    </>
  )
}

export default Courses