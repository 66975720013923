import React from 'react';
import './Home.css';
import HeroImage from './HeroImage';
import WhyChooseUs from '../../components/WhyChooseUs/WhyChooseUs';
import Cta from '../../components/Cta/Cta';
import Course from './Course';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>HSR Skills Lab - Elevate Your Skills, Elevate Your Future</title>
        <meta name="description" content="Unlock your potential with HSR Skills Lab - Your gateway to mastering programming, web development, data science, machine learning and more. Start your journey today!" />
      </Helmet>
      <HeroImage />
      <WhyChooseUs />
      <Cta />
      <Course />
    </>
  )
}

export default Home