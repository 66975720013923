import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Courses from './pages/Courses/Courses';
import FAQs from './pages/FAQs/FAQs';
import Blogs from './pages/Blogs/Blogs';
import Error from './pages/Error/Error';
import { NavBar } from './common/NavBar/NavBar';
import Footer from './common/Footer/Footer';
import { InfinitySpin } from 'react-loader-spinner';
import Disclaimer from './pages/Disclaimer/Disclaimer';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import RefundPolicy from './pages/RefundPolicy/RefundPolicy';
import ProgrammingFundamentals from './pages/Courses/CourseDetails/ProgrammingFundamentals';
import DataScience from './pages/Courses/CourseDetails/DataScience';
import MachineLearning from './pages/Courses/CourseDetails/MachineLearning';
import Mern from './pages/Courses/CourseDetails/Mern';
import FullStack from './pages/Courses/CourseDetails/FullStack';

const App = () => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(false);
  },[loader]);


  return (
    <>
      {loader ?
        <div className='hsr-loader text-center'>
          <InfinitySpin
          width='200'
          color='#0B42F4'
        />
        <p>Unleashing the code... </p>
        </div>
        :
        <>
          <NavBar />
          <BrowserRouter >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="courses" element={<Courses />} />
              {/* Course Details */}
              <Route path="course/programming-fundamentals" element={<ProgrammingFundamentals />} />
              <Route path="course/data-science-and-analytics-using-python" element={<DataScience />} />
              <Route path="course/machine-learning-using-python" element={<MachineLearning />} />
              <Route path="course/mern-stack-web-development" element={<Mern />} />
              <Route path="course/full-stack-web-development" element={<FullStack />} />
              <Route path="faqs" element={<FAQs />} />
              <Route path="contact" element={<Contact />} />
              <Route path='blogs' element={<Blogs />} />
              <Route path='disclaimer' element={<Disclaimer />} />
              <Route path='privacy-policy' element={<PrivacyPolicy />} />
              <Route path='terms-and-conditions' element={<TermsAndConditions />} />
              <Route path='refund-and-cancellation' element={<RefundPolicy />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </BrowserRouter>
          <Footer />
        </>
      }

    </>
  )
}

export default App