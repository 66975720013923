import React from 'react'

const CourseContent = () => {
    return (
        <>
            <h2>MERN Stack Development Course Syllabus and Schedule</h2>
            <br></br>
            <p><b>Course Duration:</b> 3-4 Months</p>
            <p><b>Weekend Classes:</b> 4 Hours/Day</p>
            <br></br>
            <h2>Course Syllabus</h2>
            <br></br>
            <h3>Week 1-2: Introduction to MERN Stack</h3>

            <ul>
                <li>Understanding the MERN stack architecture</li>
                <li>Setting up development environment</li>
                <li>Introduction to MongoDB and NoSQL databases</li>
                <li>Performing CRUD operations with MongoDB</li>
            </ul>
            <br></br>
            <h3>Week 3-4: Express.js for Server-Side Development</h3>

            <ul>
                <li>Building server-side applications with Express.js</li>
                <li>Creating RESTful APIs with Express.js</li>
                <li>Middleware integration for enhanced functionality</li>
                <li>Error handling and debugging in Express.js</li>
            </ul>
            <br></br>
            <h3>Week 5-6: React.js for Dynamic User Interfaces</h3>

            <ul>
                <li>Introduction to React.js and component-based architecture</li>
                <li>Managing state and props in React components</li>
                <li>Building dynamic and responsive user interfaces</li>
                <li>React Router for navigation in single-page applications</li>
            </ul>
            <br></br>
            <h3>Week 7-8: Node.js for Scalable Server-Side Applications</h3>

            <ul>
                <li>Building scalable server-side applications with Node.js</li>
                <li>Handling asynchronous operations with callbacks and Promises</li>
                <li>Utilizing the Node.js ecosystem and npm packages</li>
                <li>Debugging and optimization in Node.js applications</li>
            </ul>
            <br></br>
            <h3>Week 9-10: Authentication and Authorization</h3>

            <ul>
                <li>Implementing user authentication in MERN applications</li>
                <li>Securing routes and resources with authorization</li>
                <li>OAuth and social media authentication</li>
                <li>Best practices for user authentication and security</li>
            </ul>
            <br></br>
            <h3>Week 11-12: RESTful APIs and Integration</h3>

            <ul>
                <li>Designing and implementing RESTful APIs</li>
                <li>Consuming APIs in React.js applications</li>
                <li>Handling API requests and responses</li>
                <li>Advanced API integration techniques</li>
            </ul>
            <br></br>
            <h3>Week 13-14: Deployment and Best Practices</h3>

            <ul>
                <li>Deploying MERN stack applications to cloud platforms</li>
                <li>Configuring and optimizing deployment environments</li>
                <li>Implementing best practices in MERN stack development</li>
                <li>Code organization and project structure</li>
            </ul>
            <br></br>
            <h2>Course Schedule</h2>
            <br></br>
            <p>
                Classes will be held on weekends for 4 hours each day. Below is a tentative schedule for the course duration:
            </p>
            <br></br>
            <p>
                <b>Note:</b> The schedule is subject to change based on the pace of learning and participant feedback.

            </p>

        </>
    )
}

export default CourseContent