import React from 'react'

const CourseContent = () => {
    return (
        <>
            <h2>Machine Learning using Python Course Content Planning (6-8 Months, Weekend Classes, 4 Hours/Day)</h2>
            <br></br>

            <h3>Weeks 1-4: Foundations of Python and Introduction to Data Science (16 hours)</h3>
            <br></br>
            <ul>
                <p>Week 1-2: Python Fundamentals for Data Science (8 hours)</p>
                <ul>
                    <li>Overview of Python programming</li>
                    <li>Data types, variables, and operators</li>
                    <li>Control structures: if, else, loops</li>
                    <li>Functions and modules</li>
                </ul>
                <br></br>
                <p>Week 3: Introduction to Numpy (4 hours)</p>
                <ul>
                    <li>Understanding arrays and matrices</li>
                    <li>Array operations and manipulations</li>
                    <li>Array operations and manipulations</li>
                    <li>Linear algebra with Numpy</li>
                </ul>
                <br></br>
                <p>Week 4: Data Manipulation with Pandas (4 hours)</p>
                <ul>
                    <li>Introduction to Pandas DataFrames</li>
                    <li>Data cleaning and preprocessing</li>
                    <li>Handling missing data</li>
                    <li>Grouping and aggregating data</li>
                </ul>
            </ul>
            <br></br>
            <h3>Weeks 5-8: Data Visualization with Matplotlib and Seaborn (16 hours)</h3>
            <br></br>
            <ul>
                <p>Week 5-6: Matplotlib Basics (8 hours)</p>

                <ul>
                    <li>Basic plotting with Matplotlib</li>
                    <li>Customizing plots and visualizations</li>
                </ul>
                <br></br>
                <p>Week 7: Seaborn for Advanced Visualization (4 hours)</p>

                <ul>
                    <li>Creating informative visualizations with Seaborn</li>
                    <li>Advanced plotting techniques</li>
                </ul>
                <br></br>
                <p>Week 8: Exploratory Data Analysis (4 hours)</p>

                <ul>
                    <li>Real-world EDA examples</li>
                    <li>Insights from data visualization</li>
                </ul>
            </ul>
            <br></br>
            <h3>Weeks 9-12: Machine Learning Basics with Scikit-Learn (16 hours)</h3>
            <br></br>
            <ul>
                <p>Week 9-10: Introduction to Machine Learning (8 hours)</p>
                <ul>
                    <li>
                        Overview of supervised and unsupervised learning</li>
                    <li>Key concepts: features, labels, training, and testing</li>
                    <li>Introduction to Scikit-Learn library
                    </li>
                </ul>
                <br></br>
                <p>Week 11-12: Supervised Learning Algorithms (8 hours)</p>
                <ul>
                    <li>Linear Regression and Logistic Regression</li>
                    <li>Decision Trees and Random Forest</li>
                    <li>Support Vector Machines (SVM)</li>
                    <li>Model evaluation and hyperparameter tuning</li>
                </ul>
            </ul>
            <br></br>
            <h3>Weeks 13-16: Deep Learning with PyTorch (16 hours)</h3>

            <br></br>
            <ul>
                <p>Week 13-14: Introduction to Deep Learning with PyTorch (8 hours)</p>
                <ul>
                    <li>Basics of neural networks with PyTorch</li>
                    <li>Building and training simple neural networks</li>
                </ul>
                <br></br>
                <p>Week 15-16: Advanced Deep Learning Concepts (8 hours)</p>
                <ul>
                    <li>Convolutional Neural Networks (CNN)</li>
                    <li>Recurrent Neural Networks (RNN)</li>
                    <li>Transfer learning and fine-tuning with PyTorch</li>
                </ul>
            </ul>
            <br></br>
            <h3>Weeks 17-20: Deep Learning with Keras and TensorFlow (16 hours)</h3>
            <br></br>
            <ul>
                <p>Week 17-18: Introduction to Keras and TensorFlow (8 hours)</p>

                <ul>
                    <li>Basics of neural networks with Keras and TensorFlow</li>
                    <li>Building and training simple neural networks</li>
                </ul>
                <br></br>
                <p>Week 19-20: Advanced Deep Learning with Keras and TensorFlow (8 hours)</p>

                <ul>
                    <li>Convolutional Neural Networks (CNN) with Keras</li>
                    <li>Recurrent Neural Networks (RNN) with Keras</li>
                    <li>Transfer learning and fine-tuning with Keras</li>
                </ul>
            </ul>
            <br></br>
            <h3>Weeks 21-22: Capstone Project 1 - Real-world Application (8 hours)</h3>
            <br></br>
            <ul>
                <li>Introduction to the first capstone project</li>
                <li>Project scope, requirements, and dataset selection</li>
                <li>Mentorship and guidance during project development</li>
            </ul>
            <br></br>
            <h3>Weeks 23-24: Capstone Project 2 - Advanced Application (8 hours)</h3>
            <br></br>
            <ul>
                <li>Introduction to the second capstone project</li>
                <li>Project scope, requirements, and dataset selection</li>
                <li>Mentorship and guidance during project development</li>
            </ul>
            <br></br>
            <h2>Assessment and Certification:</h2>
<br></br>
<ul>
    <li>Weekly quizzes and assignments</li>
    <li>Mid-term assessments on foundational topics</li>
    <li>Final project evaluation for capstone projects</li>
    <li>Certificate of Completion for successful participants</li>
</ul>
<br></br>
<h2>Note:</h2>
<br></br>
<ul>
<li>Regular hands-on exercises and coding practice are integral parts of each class.</li>
<li>Additional resources and reading materials will be provided for self-paced learning.</li>
<li>Guest lectures and industry insights will be incorporated into the course.</li>
<li>Class timings and content may be adjusted based on the pace of the participants.</li>
</ul>
        </>
    )
}

export default CourseContent;