import React, { useState } from 'react';
import './CourseDetails.css';
import { Container, Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import course1 from '../../../assets/images/slide-3.png';
import OrangeButton from '../../../components/OrangeButton/OrangeButton';
import About from './Mern/About';
import Perks from './Mern/Perks';
import CourseContent from './Mern/CourseContent';
import { Helmet } from 'react-helmet';

const Mern = () => {
    const [key, setKey] = useState('about');
    const kpi = [
        {
            title: "Duration",
            value: '03-04 Months',
        },
        {
            title: "Live Class",
            value: '130+ hours',
        },
        {
            title: "Level",
            value: 'Beginner',
        },
        {
            title: "Class Type",
            value: 'Weekends',
        }
    ]
    return (
        <>
         <Helmet>
      <title>MERN Stack Web Development - HSR Skills Lab</title>
      <meta name="description" content="Master the MERN (MongoDB, Express.js, React.js, Node.js) stack at HSR Skills Lab. Build modern and scalable web applications with this popular and powerful technology stack." />
    </Helmet>
            <Container fluid className='hsr-course-details-page p-3 py-5'>
                <Container>
                    <Row className='align-items-center'>
                        <Col md={7} lg={7} sm={12}>
                            <div className='mt-3'>
                                <a href="/" className='text-white'>Home</a>
                                <span className='mx-2 text-white'>/</span>
                                <a href="/" className='text-white'>Courses</a>
                                <span className='mx-2 text-offwhite'>/</span>
                                <span className='text-offwhite'>MERN Stack Web Development</span>
                            </div>
                            <h1 className='mt-5 text-white'><b>MERN Stack Web Development</b></h1>
                            <p className='text-offwhite mt-3 mb-5'>Dive deep into MongoDB, Express.js, React.js, and Node.js. Build modern, scalable web applications with a focus on the MERN stack's powerful capabilities.</p>
                            <OrangeButton text="Enroll Now"  action={()=>window.location.href="https://rzp.io/l/mern-registration"} />
                        </Col>
                        <Col md={5} lg={5} sm={12}>
                            <div className='text-center my-4'>
                                <img className='w-100' style={{ objectFit: 'contain' }} height={200} alt="Programming Fundamentals" src={course1} />
                            </div>
                        </Col>
                    </Row>
                    <div style={{ height: '65px' }}></div>
                </Container>
            </Container>
            <Container className='card rounded-4 border-0 p-3 hsr-course-kpi'>
                <Row className='gx-0 gy-4'>
                    {kpi.map((d) => {
                        return (
                            <Col md={3} lg={3} xs={6} className='hsr-course-kpi-wrapper'>
                                <div className='hsr-right-border hsr-kpi-unit d-flex align-items-center'>
                                    <div className='text-center w-100'>
                                        <h5><b>{d.title}</b></h5>
                                        <p className='mb-0 mt-3'>{d.value}</p>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
            <Container className='mb-5 hsr-course-details'>
                <Row className='gy-5'>
                    <Col md={9} lg={9} xs={12}>
                        <Card className='border-0 rounded-0'>
                            <Tabs
                                defaultActiveKey="profile"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                fill
                            >
                                <Tab eventKey="about" title="About Course">
                                    <div className='p-4 hsr-content'>
                                        <About />
                                    </div>
                                </Tab>
                                <Tab eventKey="content" title="Course Content">
                                    <div className='p-4 hsr-content'>
                                        <CourseContent />
                                    </div>
                                </Tab>
                                <Tab eventKey="perks" title="Perks & Benefits">
                                    <div className='p-4 hsr-content'>
                                        <Perks />
                                    </div>
                                </Tab>
                            </Tabs>
                        </Card>
                    </Col>
                    <Col lg={3} md={3} xs={12}>
                        <Card className='border-0 rounded-4 p-4'>
                            <div className='mb-3 text-center'>
                                <h1 className='mb-0'><b>₹ 4499</b></h1>
                                <h2 className='text-danger'><s>&nbsp; ₹ 7499 &nbsp;</s><span className='ms-3 text-success'>40% OFF</span></h2>
                                <p className='text-sm'>(Offer Valid till January 31, 2024)</p>
                                <OrangeButton text="Enroll Now"  action={()=>window.location.href="https://rzp.io/l/mern-registration"} />
                            </div>
                            <hr></hr>
                            <div className='d-flex align-items-center mt-3'>
                                <i className='bi bi-geo-alt'></i>
                                <div className='ms-3'>
                                    <p className='mb-0 text-sm'>Location</p>
                                    <p className='mb-0 text-lg'>Remote</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-3'>
                                <i className='bi bi-clock'></i>
                                <div className='ms-3'>
                                    <p className='mb-0 text-sm'>Duration</p>
                                    <p className='mb-0 text-lg'>03-04 Months (130+ Hours)</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-3'>
                                <i className='bi bi-calendar-date'></i>
                                <div className='ms-3'>
                                    <p className='mb-0 text-sm'>Start Date</p>
                                    <p className='mb-0 text-lg'>April 15, 2024</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-3'>
                                <i className='bi bi-bar-chart-steps'></i>
                                <div className='ms-3'>
                                    <p className='mb-0 text-sm'>Level</p>
                                    <p className='mb-0 text-lg'>Beginner</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-3'>
                                <i className='bi bi-person-video3'></i>
                                <div className='ms-3'>
                                    <p className='mb-0 text-sm'>Class Type</p>
                                    <p className='mb-0 text-lg'>Weekends (4hrs/day)</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-3'>
                                <i className='bi bi-patch-check'></i>
                                <div className='ms-3'>
                                    <p className='mb-0 text-sm'>Certification</p>
                                    <p className='mb-0 text-lg'>Participation Certificate</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-3'>
                                <i className='bi bi-cast'></i>
                                <div className='ms-3'>
                                    <p className='mb-0 text-sm'>Project</p>
                                    <p className='mb-0 text-lg'>1 Capstone Project</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-3'>
                                <i className='bi bi-laptop'></i>
                                <div className='ms-3'>
                                    <p className='mb-0 text-sm'>Internship</p>
                                    <p className='mb-0 text-lg'>3 Months Free Internship</p>
                                </div>
                            </div>

                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Mern