import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
    return (
        <>
        <Helmet>
      <title>Terms & Conditions - HSR Skills Lab</title>
      <meta name="description" content="Explore the terms and conditions of using HSR Skills Lab's services. Familiarize yourself with the guidelines and agreements to ensure a smooth and enjoyable learning experience." />
    </Helmet>
            <Container className='py-5'>
                <Row className='justify-content-center'>
                    <Col md={8} lg={8} sm={12} >
                        <div className="paper p-4 shadow">
                            <div className="pin">
                                <div className="shadow"></div>
                                <div className="metal"></div>
                                <div className="bottom-circle"></div>
                            </div>
                            <div>
                                <div className='hsr-section-heading my-5'>
                                    <h1>Terms & Conditions</h1>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <span className='hsr-divider'></span>
                                        <i className='bi bi-laptop mx-3 hsr-separator'></i>
                                        <span className='hsr-divider'></span>
                                    </div>
                                </div>
                                <div className='my-5'>
                                    <p><b>Last Updated: December 18, 2023</b></p>
                                    <p>Welcome to HSR Skills Lab, a unit of <a href="https://www.hsrsolutions.co.in/" target='_blank' className='text-dark'>HSR Hi-Tech Solutions</a> for Skill Development. By accessing our website or participating in our skill development programs, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our services.</p>

                                    <ol className='mt-4'>
                                        <li>
                                            <p><b> Acceptance of Terms:</b></p>
                                            <p>By using HSR Skills Lab services, you acknowledge that you have read, understood, and agreed to these terms and conditions. If you do not agree with any part of these terms, please do not use our services.</p>
                                        </li>
                                        <li>
                                            <p><b>Program Enrollment:</b></p>
                                            <ol type="1">
                                                <li>
                                                    <p><b>Eligibility:</b></p>
                                                    <p>To enroll in our skill development programs, you must meet the eligibility criteria specified for each program. We reserve the right to reject enrollment if eligibility requirements are not met.</p>
                                                </li>
                                                <li>
                                                    <p><b>Registration Information:</b></p>
                                                    <p>You agree to provide accurate and complete registration information. You are responsible for maintaining the confidentiality of your account and password.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p><b>Payment and Fees:</b></p>
                                            <ol type="1">
                                                <li>
                                                    <p><b>Program Fees:</b></p>
                                                    <p>Fees for skill development programs are specified on our website. Payment must be made in full before the start of the program.</p>
                                                </li>
                                                <li>
                                                    <p><b>Refunds:</b></p>
                                                    <p>Refund policies are outlined for each program. Refunds are subject to these policies.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p><b>Intellectual Property:</b></p>
                                            <ol type="1">
                                                <li>
                                                    <p><b>Course Materials:</b></p>
                                                    <p>All course materials, including but not limited to lectures, presentations, and resources, are the intellectual property of HSR Skills Lab. Unauthorized distribution or reproduction is prohibited.</p>
                                                </li>
                                                <li>
                                                    <p><b>Use of Logo and Name:</b></p>
                                                    <p>Participants agree that HSR Skills Lab may use their name and logo for promotional purposes, including testimonials and marketing materials.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p><b>Code of Conduct:</b></p>
                                            <ol type="1">
                                                <li>
                                                    <p><b>Respect:</b></p>
                                                    <p>Participants must treat instructors and fellow participants with respect. Harassment, discrimination, or disruptive behavior will not be tolerated.</p>
                                                </li>
                                                <li>
                                                    <p><b>Compliance with Policies:</b></p>
                                                    <p>Participants must comply with all HSR Skills Lab policies and guidelines outlined in these terms and any additional guidelines provided.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p><b>Limitation of Liability:</b></p>
                                            <p>HSR Skills Lab, its employees, instructors, and affiliates shall not be held liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with the use of our services.</p>
                                        </li>
                                        <li>
                                            <p><b>Changes to Terms and Conditions:</b></p>
                                            <p>We reserve the right to update or modify these terms and conditions at any time without prior notice. It is your responsibility to review these terms periodically for changes.</p>
                                        </li>
                                        <li>
                                            <p><b>Governing Law:</b></p>
                                            <p>These terms and conditions are governed by and construed in accordance with the laws of Bihar, India. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of Bihar, India.</p>
                                        </li>
                                        <li>
                                            <p><b>Contact Us:</b></p>
                                            <p>If you have any questions or concerns about these terms and conditions, please contact us at <a href="mailto:info@hsrsolutions.co.in" target='_blank'>info@hsrsolutions.co.in</a>.</p>
                                        </li>
                                    </ol>
                                    <p>By using our services, you acknowledge that you have read, understood, and agreed to the terms and conditions outlined in this disclaimer.</p>
                                    <br></br>
                                    <p>If you have any questions or concerns regarding this disclaimer, please contact us at <a href="mailto:info@hsrsolutions.co.in" target='_blank'>info@hsrsolutions.co.in</a></p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TermsAndConditions