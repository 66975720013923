import React from 'react';
import './Error.css';
import OrangeButton from '../../components/OrangeButton/OrangeButton';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Error = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="page_404">
        <Container>
          <Row className='justify-content-center'>
            <Col lg={8} md={10} sm={12} className='text-center'>
              <div className="four_zero_four_bg">
                  <h1 className="text-center ">404</h1>
                </div>
                <div className="contant_box_404">
                  <h3 className="h2">
                    Look like you're lost
                  </h3>

                  <p>the page you are looking for is not available!</p>
                  <OrangeButton text={"Go to Home"} action={()=>navigate('/')} />
                </div>
              </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Error