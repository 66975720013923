import { Carousel, Col, Container, Row } from 'react-bootstrap'
import React from 'react'
import OrangeButton from '../../components/OrangeButton/OrangeButton';
import slide1 from '../../assets/images/slide-1.png';
import slide2 from '../../assets/images/slide-2.png';
import slide3 from '../../assets/images/slide-3.png';
import slide4 from '../../assets/images/slide-4.png';
import slide5 from '../../assets/images/slide-5.png';

const HeroImage = () => {
    const data = [
        {
            one_liner: 'Unleash Code Power in 1 Month',
            course_title: 'Programming Fundamentals',
            course_duration: '1 Month',
            course_price: '₹ 1499',
            total_price: '₹ 2499',
            image: slide1,
            description: 'Unlock the world of coding with our foundational programming course. Master key languages like Python, Java, and C for a solid programming foundation.',
            url: 'course/programming-fundamentals',
            btn_text: 'Start Coding Journey',
            discount: '40% OFF',
        },
        {
            one_liner: 'Craft Web Brilliance in 9-12 Months',
            course_title: 'Full Stack Web Development',
            course_duration: '9-12 Months',
            course_price: '₹ 8999',
            total_price: '₹ 17998',
            image: slide2,
            description: 'Journey from front-end to back-end to databases mastery. Learn HTML, CSS, JavaScript, PHP, Python, Django, React js, Node js, Express js, Bootstrap, MongoDB, MySQL, GA4 and many more technologies to create seamless and dynamic web applications.',
            url: '/course/full-stack-web-development',
            btn_text: 'Build Web Mastery',
            discount: '50% OFF',
        },
        {
            one_liner: 'Elevate Web Experiences in 3-4 Months',
            course_title: 'MERN Stack Web Development',
            course_duration: '3-4 Months',
            course_price: '₹ 4499',
            total_price: '₹ 7499',
            image: slide3,
            description: "Dive deep into MongoDB, Express.js, React.js, and Node.js. Build modern, scalable web applications with a focus on the MERN stack's powerful capabilities.",
            url: '/course/mern-stack-web-development',
            btn_text: 'Explore MERN Stack',
            discount: '40% OFF',
        },
        {
            one_liner: 'Decode Data Secrets in 3-4 Months',
            course_title: 'Data Science and Analytics using Python',
            course_duration: '3-4 Months',
            course_price: '₹ 4499',
            total_price: '₹ 7499',
            image: slide4,
            description: "Uncover actionable insights from data using Python. Explore data analysis and visualization techniques to make data-driven decisions.",
            url: '/course/data-science-and-analytics-using-python',
            btn_text: 'Discover Data Insights',
            discount: '40% OFF',
        },
        {
            one_liner: 'Empower AI Wisdom in 6-8 Months',
            course_title: 'Machine Learning using Python',
            course_duration: '6-8 Months',
            course_price: '₹ 7199',
            total_price: '₹ 11999',
            image: slide5,
            description: "Step into the realm of artificial intelligence. Learn machine learning algorithms, model training, and deployment using Python for intelligent solutions.",
            url: '/course/machine-learning-using-python',
            btn_text: 'Embark on AI Path',
            discount: '40% OFF',
        }
    ]
    return (
        <>
            <Carousel indicators={true} controls={false}>
                {data.map((d) => {
                    return (
                        <Carousel.Item>
                            <Container fluid className='hsr-carousel-slide'>
                                <Container>
                                    <Row className='align-items-center hsr-carousel-item'>
                                        <Col md={7} lg={7} sm={12}>
                                            <span className='hsr-one-liner'>{d.one_liner}</span>
                                            <h1 className='hsr-main-title'>
                                                {d.course_title}
                                            </h1>
                                            <p className='text-offwhite mb-4'>{d.description}</p>

                                            <div className='d-flex mb-4 align-items-center'>
                                            <h2 className='text-white'>{d.course_price}</h2>
                                            <h4 className='mx-3'><s className='text-warning'>&nbsp;{d.total_price}&nbsp;</s></h4>
                                            <h4><span className='text-dark'>{d.discount}</span></h4>
                                            </div>

                                            <OrangeButton action={()=>window.location.href=d.url} text={d.btn_text} />
                                        </Col>
                                        <Col md={5} lg={5} sm={12}>
                                            <img className='w-100' src={d.image} alt={d.course_title} />
                                        </Col>
                                    </Row>
                                </Container>
                            </Container>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </>
    )
}

export default HeroImage