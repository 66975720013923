import React from 'react';
import './CourseCard.css';
import { Card } from 'react-bootstrap';

const CourseCard = (props) => {
  return (
    <>
    <Card className='border-0 rounded-4 p-3 hsr-course-card'>
        <img src={props.item.image} alt={props.item.course_title} />
        <div className='hsr-course-body'>
        <h4>{props.item.course_title}</h4>
        <p className='text-center mb-1 text-secondary'><b>({props.item.course_duration})</b></p>
        <p className='text-sm'>{props.item.description}</p>
        <div className='d-flex justify-content-between'>
            <h5><b><s className='text-danger'> {props.item.total_price} </s>&nbsp; &nbsp;<span className='text-success'>{props.item.course_price}</span></b></h5>
            <a href={props.item.url}><b>View Details</b></a>
        </div>
        </div>
    </Card>
    </>
  )
}

export default CourseCard