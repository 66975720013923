import React from 'react'

const About = () => {
    return (
        <>
            <h2>About the Programming Fundamentals Course</h2>
            <br></br>
            <p>Welcome to our Programming Fundamentals course! This comprehensive program is designed to provide you with a solid foundation in programming concepts using languages such as C, Python, or Java. Whether you're a beginner seeking to learn the basics or someone looking to strengthen their programming skills, this course is tailored to meet your needs.</p>
            <br></br>
            <h2>Course Overview:</h2>
            <p>This 1-month course, consisting of 30 hours of live classes, focuses on fundamental programming concepts essential for any aspiring developer. The course is structured into four weeks, each covering specific topics to ensure a gradual and thorough understanding of programming fundamentals.</p>
            <br></br>
            <h3>Week 1: Introduction to Programming Concepts and Basics</h3>

            <p>During the first week, you'll dive into the world of programming, learning about different paradigms and getting hands-on experience with the syntax and structure of your chosen language. Topics include setting up development environments, working with variables and data types, and understanding control statements like if, else, and loops.</p>
            <br></br>
            <h3>Week 2: More on Loops and Functions</h3>

            <p>Building on the basics, Week 2 explores advanced control structures, including for loops, while loops, and functions. You'll gain practical insights into modular programming and how to use functions effectively. The week culminates in a mini-project where you'll apply your knowledge to solve real-world problems.</p>
            <br></br>
            <h3>Week 3: File Handling and Error Handling</h3>

            <p>In Week 3, you'll delve into file handling, learning how to read and write files in your chosen language. The importance of error handling and exception management will be emphasized, ensuring your code is robust and resilient. The week concludes with a review session and practice exercises to solidify your understanding.</p>
            <br></br>
            <h3>Week 4: Final Coding Challenges and Course Wrap-up</h3>

            <p>The final week is dedicated to honing your skills through coding challenges. You'll tackle progressively complex problems, showcasing your ability to apply programming fundamentals. The course wraps up with a comprehensive review, Q&A session, and a final project showcase where you can present the mini-project you've developed during the course.</p>
            <br></br>
            <h3>Assessment and Practical Application:</h3>

            <p>Throughout the course, your understanding will be assessed through weekly quizzes, coding exercises, and a final coding challenge. These assessments are designed to reinforce your learning and provide valuable hands-on experience in problem-solving.</p>
            <br></br>
            <h2>Why Choose This Course?</h2>
            <br></br>
            <ul>
                <li><strong>Structured Learning Path:</strong> Follow a well-organized curriculum designed for progressive skill development.</li>
                <li><strong>Practical Application:</strong> Apply your knowledge through coding exercises and a final mini-project.</li>
                <li><strong>Language Flexibility:</strong> Choose between C, Python, or Java based on your preferences or career goals.</li>
                <li><strong>Expert Instruction:</strong> Learn from experienced instructors dedicated to your success.</li>
                <li><strong>Community and Networking:</strong> Join a community of learners and engage in collaborative projects.</li>
            </ul>
            <br></br>
            <h2>Requirements:</h2>
            <br></br>
            <p>No prior programming experience is required. All you need is a passion for learning and a computer with internet access. The course is suitable for beginners and those looking to reinforce their programming fundamentals.</p>
            <br></br>
            <h2>Join Us on this Exciting Learning Journey!</h2>
            <br></br>
            <p>Embark on your journey to becoming a proficient programmer. Whether you dream of developing software, working in data science, or exploring the vast world of technology, this course lays the groundwork for your success. Enroll now and unlock the doors to endless possibilities in the world of programming!</p>

        </>
    )
}

export default About