import React from 'react'

const Perks = () => {
    return (
        <>
            <h2>Perks & Benefits of the Full-Stack Web Development Mastery Course</h2>
            <br></br>
            <p>
                Enrolling in our 9-month Full-Stack Web Development Mastery Course comes with a range of perks and benefits that extend beyond technical skill acquisition. Here's what you can expect:
            </p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i>  Comprehensive Learning Experience</h3>

            <p>
                Gain expertise in a diverse range of technologies, from front-end to back-end, databases, and project development. Our comprehensive curriculum ensures you are well-versed in the latest tools and frameworks.
            </p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i>  Hands-On Projects</h3>

            <p>
                Apply theoretical knowledge through three mini projects and three capstone projects. These projects provide practical experience and an opportunity to showcase your skills in real-world scenarios.
            </p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i>  Expert Instruction</h3>

            <p>
                Learn from industry experts with extensive experience in web development. Benefit from their insights, guidance, and best practices, ensuring you receive top-notch instruction throughout the course.
            </p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i>  Internship Opportunity</h3>

            <p>
                Upon successful completion of the course, you are eligible for a 3-month free internship, providing you with hands-on experience in a professional setting. This internship is a valuable opportunity to apply your skills, gain practical insights, and enhance your resume.
            </p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i>  Participation Certificate</h3>

            <p>
                Receive a participation certificate at the end of the course, acknowledging your dedication and successful completion of the Full-Stack Web Development Mastery Course. This certificate is a valuable addition to your portfolio, showcasing your commitment to continuous learning.
            </p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i>  Networking Opportunities</h3>

            <p>
                Connect with instructors, industry professionals, and fellow learners, expanding your professional network. Networking opportunities may open doors to collaboration, mentorship, and future career prospects.
            </p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i>  Career Advancement</h3>

            <p>
                Acquire a skill set that is highly in demand in the tech industry, opening doors to diverse career opportunities. Whether you aim to become a full-stack developer, project manager, or entrepreneur, our course provides a solid foundation for career growth.
            </p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i>  Flexibility and Convenience</h3>

            <p>
                Benefit from weekend classes, each lasting 3 hours, allowing you to balance learning with other commitments. This flexibility makes the course accessible to working professionals and students seeking to upskill.
            </p>
<br></br>
            <p>
                Embark on a transformative journey into the dynamic field of full-stack web development. Enroll today and unlock a world of opportunities in the ever-evolving tech industry.

            </p>

        </>
    )
}

export default Perks