import React from 'react';
import './About.css';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'react-bootstrap';
import about from '../../assets/images/about.webp';

const About = () => {
  return (
    <>
      <Helmet>
      <title>About Us - HSR Skills Lab</title>
      <meta name="description" content="Discover the story behind HSR Skills Lab - a place committed to fostering skills, knowledge, and innovation. Learn more about our mission and values." />
    </Helmet>
      <Container fluid className='hsr-page-title'>
        <Container>
          <div className='hsr-section-heading py-5'>
            <h1 className='text-white'>About Us</h1>
            <div className='d-flex align-items-center justify-content-center'>
              <span className='hsr-divider bg-white'></span>
              <i className='bi bi-laptop mx-3 hsr-separator'></i>
              <span className='hsr-divider bg-white'></span>
            </div>
            <div className='text-center mt-3'>
              <a href="/" className='text-white'>Home</a>
              <span className='mx-2 text-offwhite'>/</span>
              <span className='text-offwhite'>About Us</span>
            </div>
          </div>
        </Container>
      </Container>
      <Container>
        <div className='my-5'>
          <Row>
            <Col md={6} lg={6} sm={12}>
              <p>Welcome to HSR Skills Lab, a trailblazing unit under HSR Hi-Tech Solutions for Skill Development. At HSR Skills Lab, we are on a mission to redefine education in the realms of IT and Programming. Our commitment is not just to provide courses but to sculpt skill sets that shape thriving careers.</p>
              <p><b>Our Mission</b></p>

              <p>
                In a world where technology evolves at an unprecedented pace, our mission is clear - to bridge the gap between traditional education and the dynamic demands of the industry. We believe in the transformative power of skill development, not only as a gateway to lucrative careers but also as a catalyst for innovation in the IT sector.
              </p>
              <p><b>Why Choose HSR Skills Lab?</b></p>
              <p>Choosing HSR Skills Lab means choosing excellence, innovation, and a learning experience that goes beyond the ordinary.</p>
              <ul>
                <li className='my-3'><b>Expert Instructors:</b> Our programs are meticulously crafted and delivered by industry experts and seasoned professionals. Learn not just theory but industry insights from those who've walked the path.</li>

                <li className='my-3'><b>Cutting-Edge Curriculum:s</b> Stay ahead in the fast-paced world of technology. Our curriculum is a living entity, constantly updated to reflect the latest trends and technologies in IT and Programming.</li>

                <li className='my-3'><b>Hands-On Learning:</b> We believe in learning by doing. Engage in projects that mirror real-world scenarios, providing you with practical experience that sets you apart.</li>

                <li className='my-3' >
                  <b>Community and Collaboration:</b> HSR Skills Lab is more than a learning platform; it's a vibrant community. Collaborate, network, and grow with like-minded individuals, learning not just from instructors but from each other.
                </li>

                <li className='my-3'>
                  <b>Career Guidance:</b> Education is just the beginning. Our career guidance services are designed to empower you on your professional journey. Build an impressive portfolio, prepare for interviews, and confidently step into the industry.
                </li>
              </ul>
            </Col>
            <Col md={6} lg={6} sm={12}>
              <img src={about} alt="HSR Skills Lab" className='p-2 bg-white rounded-4 w-100 shadow' />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default About