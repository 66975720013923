import React from 'react'

const Perks = () => {
    return (
        <>
            <h2>Perks & Benefits of the Programming Fundamentals Course</h2>
<br></br>
            <h3 className='text-success'><i className="bi bi-check2-all"></i> Comprehensive Learning Path</h3>
            <p>Our course provides a well-structured and comprehensive learning path, guiding you through fundamental programming concepts. Each week builds upon the previous, ensuring a smooth and progressive learning experience.</p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i> Practical Application</h3>
            <p>We emphasize practical application through coding exercises and a mini-project. This hands-on approach allows you to immediately apply the concepts you learn, reinforcing your understanding and preparing you for real-world scenarios.</p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i> Language Flexibility</h3>
            <p>Choose between C, Python, or Java based on your preferences or career goals. This flexibility enables you to learn a language that aligns with your interests or the specific requirements of your desired industry.</p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i> Expert Instruction</h3>
            <p>Learn from experienced instructors dedicated to your success. Our instructors bring a wealth of industry knowledge and teaching expertise to ensure you receive high-quality guidance throughout the course.</p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i> Community and Networking</h3>
            <p>Join a community of like-minded learners, fostering collaboration and networking opportunities. Engage with peers, participate in group projects, and benefit from the collective knowledge and support of the learning community.</p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i> Skill Reinforcement Through Assessments</h3>
            <p>Assess your understanding and reinforce your skills through weekly quizzes, coding exercises, and a final coding challenge. These assessments provide valuable feedback and measure your progress as you advance through the course.</p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i> No Prior Experience Required</h3>
            <p>This course is designed for individuals with no prior programming experience. Whether you're a beginner or looking to reinforce your fundamentals, the course accommodates learners at various skill levels.</p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i> Accessible Anytime, Anywhere</h3>
            <p>Enjoy the flexibility of accessing course materials and participating in live classes from anywhere with an internet connection. Our online platform allows you to learn at your own pace and accommodate your schedule.</p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i> Gateway to Endless Possibilities</h3>
            <p>By completing this course, you lay the groundwork for success in various fields, including software development, data science, and technology. Programming fundamentals are the building blocks for a range of exciting career paths.</p>

            <h3 className='text-success'><i className="bi bi-check2-all"></i> Certificate of Completion</h3>
            <p>Upon successfully completing the course, you'll receive a certificate of completion. This recognition showcases your commitment to learning and mastering programming fundamentals, enhancing your resume and professional profile.</p>
<br></br>
            <h2>Embark on Your Learning Journey Today!</h2>
            <p>Join us on this exciting learning journey and unlock the perks and benefits of mastering programming fundamentals. Whether you're pursuing a career in technology or expanding your skill set, this course is your gateway to success in the dynamic world of programming.</p>

        </>
    )
}

export default Perks