import React from 'react'

const CourseContent = () => {
    return (
        <>
            <h2>Programming Fundamentals Course Schedule with Control Statements (1.5 hours/day, 5 days/week)</h2>
            <br></br>
            <h3>Week 1: Introduction to Programming Concepts and Basics (7.5 hours)</h3>
            <br></br>
            <ul>
                <p><strong>Day 1:</strong> Introduction to Programming (1.5 hours)</p>
                <ul>
                    <li>Overview of programming concepts</li>
                    <li>Introduction to control statements</li>
                    <li>Basic syntax and structure (language-dependent)</li>
                </ul>
                <br></br>

                <p><strong>Day 2:</strong> Variables and Data Types (1.5 hours)</p>
                <ul>
                    <li>Declaring and initializing variables</li>
                    <li>Basic data types: int, float, char, string</li>
                </ul>
                <br></br>

                <p><strong>Day 3:</strong> Control Statements - Part 1 (1.5 hours)</p>
                <ul>
                    <li>Conditional statements: if, else (language-dependent)</li>
                    <li>Practical examples and exercises</li>
                </ul>
                <br></br>

                <p><strong>Day 4:</strong> Control Statements - Part 2 (1.5 hours)</p>
                <ul>
                    <li>Introduce elif (language-dependent)</li>
                    <li>More examples and exercises</li>
                </ul>

                <br></br>
                <p><strong>Day 5:</strong> Loops - Part 1 (1.5 hours)</p>
                <ul>
                    <li>Introduction to loops: while loop</li>
                    <li>Examples and exercises</li>
                </ul>

            </ul>
            <br></br>
            <h3>Week 2: More on Loops and Functions (7.5 hours)</h3>
            <br></br>
            <ul>
                <p><strong>Day 6:</strong> Loops - Part 2 (1.5 hours) </p>
                <ul>
                    <li>For loop and its applications</li>
                    <li>Examples and exercises</li>
                </ul>

                <br></br>
                <p><strong>Day 7:</strong> Functions - Part 1 (1.5 hours)</p>
                <ul>
                    <li>Defining functions</li>
                    <li>Function parameters and return values</li>
                </ul>

                <br></br>
                <p><strong>Day 8:</strong> Functions - Part 2 (1.5 hours)</p>
                <ul>
                    <li>More on functions and their practical use</li>
                    <li>Function exercises</li>
                </ul>

                <br></br>
                <p><strong>Day 9:</strong> Review and Practice (1.5 hours)</p>
                <ul>
                    <li>Recap of the week's concepts</li>
                    <li>Coding exercises and practice problems</li>
                </ul>
                <br></br>

                <p><strong>Day 10:</strong> Mini-Project (1.5 hours)</p>
                <ul>
                    <li>Apply learned concepts in a small coding project</li>
                    <li>Project discussion and review</li>
                </ul>

            </ul>
            <br></br>
            <h3>Week 3: File Handling and Error Handling (7.5 hours)</h3>
            <br></br>
            <ul>
                <p><strong>Day 11:</strong> File Handling - Part 1 (1.5 hours)</p>
                <ul>
                    <li>Introduction to reading and writing files</li>
                </ul>

                <br></br>
                <p><strong>Day 12:</strong> File Handling - Part 2 (1.5 hours)</p>
                <ul>
                    <li>More on file handling and practical exercises</li>
                </ul>

                <br></br>
                <p><strong>Day 13:</strong> Error Handling - Part 1 (1.5 hours)</p>
                <ul>
                    <li>Introduction to error handling and exceptions</li>
                </ul>
                <br></br>
                <p><strong>Day 14:</strong> Error Handling - Part 2 (1.5 hours)</p>
                <ul>
                    <li>Handling errors in code</li>
                    <li>Practical examples</li>
                </ul>
                <br></br>

                <p><strong>Day 15:</strong> Review and Practice (1.5 hours)</p>
                <ul>
                    <li>Recap of the week's concepts</li>
                    <li>Coding exercises and practice problems</li>
                </ul>

            </ul>
            <br></br>
            <h3>Week 4: Final Coding Challenges and Course Wrap-up (6 hours)</h3>
            <br></br>
            <ul>
                <p><strong>Day 16:</strong> Coding Challenges - I (1.5 hours)</p>
                <ul>
                    <li>Solve coding problems to reinforce programming skills</li>
                </ul>
                <br></br>

                <p><strong>Day 17:</strong> Coding Challenges - II (1.5 hours)</p>
                <ul>
                    <li>More coding challenges and problem-solving</li>
                </ul>
                <br></br>

                <p><strong>Day 18:</strong> Coding Challenges - III (1.5 hours)</p>
                <ul>
                    <li>Final set of coding challenges</li>
                </ul>
                <br></br>

                <p><strong>Day 19:</strong> Course Review and Q&A (1.5 hours)</p>
                <ul>
                    <li>Review of key concepts</li>
                    <li>Open session for student questions</li>
                </ul>
                <br></br>

                <p><strong>Day 20:</strong> Final Project Showcase (1.5 hours)</p>
                <ul>
                    <li>Students showcase their mini-projects</li>
                    <li>Feedback and wrap-up</li>
                </ul>
            </ul>
            <br></br>
            <p><strong>Assessment:</strong> Weekly quizzes, Coding exercises and assignments, Final coding challenge evaluation</p>

            <p><strong>Note:</strong> This course focuses on core programming fundamentals, especially control statements, in C, Python, or Java. Practical coding exercises and challenges will be emphasized to reinforce learning. Regular attendance and participation are crucial for a comprehensive understanding of the material.</p>
        </>
    )
}

export default CourseContent