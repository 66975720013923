import React from 'react';
import './OrangeButton.css';

const OrangeButton = (props) => {
  return (
    <>
    <button className='hsr-theme-btn' onClick={props.action}>{props.text}</button>
    </>
  )
}

export default OrangeButton;