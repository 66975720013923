import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import image1 from '../../assets/images/slide-1.png';
import image2 from '../../assets/images/slide-2.png';
import image3 from '../../assets/images/slide-3.png';
import CourseCard from '../../components/CourseCard/CourseCard';
import OrangeButton from '../../components/OrangeButton/OrangeButton';


const Course = () => {
    const data = [
        {
          course_title: 'Programming Fundamentals',
          course_duration: '1 Month',
          course_price: '₹ 1499',
          total_price: '₹ 2499',
          image: image1,
          description: 'Unlock the world of coding with our foundational programming course. Master key languages like Python/Java/C for a solid programming foundation.',
          url: 'course/programming-fundamentals',
        },
        {
          course_title: 'Full Stack Web Development',
          course_duration: '9-12 Months',
          course_price: '₹ 8999',
          total_price: '₹ 17998',
          image: image2,
          description: 'Journey from front-end to back-end to databases mastery. Learn different technologies to create seamless and dynamic web applications.',
          url: '/course/full-stack-web-development'
        },
        {
          course_title: 'MERN Stack Web Development',
          course_duration: '3-4 Months',
          course_price: '₹ 4499',
          total_price: '₹ 7499',
          image: image3,
          description: "Dive deep into MongoDB, Express.js, React.js, and Node.js. Build modern, scalable web applications with focus on the MERN stack's powerful capabilities.",
          url: '/course/mern-stack-web-development'
        }
      ]
      
    return (
        <>
            <section className="py-5">
                <Container>
                    <div className='hsr-section-heading my-5'>
                        <h1>Our Courses</h1>
                        <div className='d-flex align-items-center justify-content-center'>
                            <span className='hsr-divider'></span>
                            <i className='bi bi-laptop mx-3 hsr-separator'></i>
                            <span className='hsr-divider'></span>
                        </div>
                    </div>
                </Container>
                <Container>
                    <Row className='gy-5 mb-5'>
                        {data.map((d) => {
                            return (
                                <Col lg={4} md={4} sm={12}>
                                    <CourseCard item={d} />
                                </Col>
                            )
                        })}
                    </Row>
                    <div className='my-4 text-center'>
                        <OrangeButton action={()=>window.location.href='/courses'} text={'All Courses'} />
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Course