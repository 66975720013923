import React from 'react'

const Perk = () => {
  return (
    <>
      

        <h2>Perks & Benefits of Choosing the Data Science and Analytics using Python Course</h2>
<br></br>
<h3 className='text-success'><i className="bi bi-check2-all"></i> Comprehensive Learning Experience</h3>
        <p>Gain a comprehensive understanding of data science and analytics, covering essential libraries such as NumPy, Pandas, and Matplotlib. The course is designed to equip you with the skills needed for real-world applications.</p>

        <h3 className='text-success'><i className="bi bi-check2-all"></i> Hands-On Experience with Python Libraries</h3>
        <p>Get hands-on experience with industry-standard Python libraries, including NumPy, Pandas, and Matplotlib. Learn to manipulate and analyze data, and create impactful visualizations that convey meaningful insights.</p>

        <h3 className='text-success'><i className="bi bi-check2-all"></i> Flexible Weekend Classes</h3>
        <p>Enjoy the flexibility of weekend classes, with 4 hours each day. This schedule is designed to accommodate working professionals and individuals with busy weekday commitments, allowing you to balance learning with your other responsibilities.</p>

        <h3 className='text-success'><i className="bi bi-check2-all"></i> Real-World Application through Capstone Projects</h3>
        <p>Apply your newfound skills to real-world scenarios through two capstone projects. Tackle challenges, solve problems, and present your findings, gaining practical experience that sets you apart in the field of data science and analytics.</p>

        <h3 className='text-success'><i className="bi bi-check2-all"></i> Expert Instructors with Industry Experience</h3>
        <p>Learn from experienced instructors with a wealth of knowledge and practical experience in the field. Benefit from their insights, guidance, and real-world anecdotes, enhancing your understanding of data science concepts.</p>

        <h3 className='text-success'><i className="bi bi-check2-all"></i> Continuous Assessment and Certificate of Completion</h3>
        <p>Your progress will be continuously assessed through assignments, quizzes, and participation. Upon successful completion of the course, receive a certificate recognizing your proficiency in data science and analytics using Python.</p>

        <h3 className='text-success'><i className="bi bi-check2-all"></i> Free 3-Month Internship Opportunity</h3>
        <p>As a bonus, participants in this course are eligible for a free 3-month internship opportunity. Gain practical experience, apply your skills in a professional setting, and further enhance your resume with valuable work experience in the field of data science.</p>

        <h3 className='text-success'><i className="bi bi-check2-all"></i> No Prior Experience Required</h3>
            <p>This course is designed for individuals with no prior programming experience. Whether you're a beginner or looking to reinforce your fundamentals, the course accommodates learners at various skill levels.</p>

        <h3 className='text-success'><i className="bi bi-check2-all"></i> Networking Opportunities</h3>
        <p>Connect with fellow learners, instructors, and industry professionals through our learning community. Engage in discussions, share insights, and expand your network, opening doors to potential collaborations and future opportunities.</p>
<br></br>
        <h2>Embark on Your Data Science Journey Today!</h2>
        <p>By choosing this course, you not only invest in your education but also set yourself on a path towards a rewarding career in data science and analytics. Join us on this enriching journey and seize the opportunities that await in the dynamic world of data.</p>


    </>
  )
}

export default Perk