import React from 'react'

const Perks = () => {
  return (
    <>

    <h2>Perks & Benefits of the MERN Stack Development Course</h2>
<br></br>
    <p>
        Enrolling in our MERN Stack Development Course offers a plethora of perks and benefits that go beyond acquiring technical skills. Here's what you can expect:
    </p>
<br></br>
<h3 className='text-success'><i className="bi bi-check2-all"></i> In-Demand Skill Set</h3>

    <p>
        Mastering the MERN stack equips you with a highly sought-after skill set in the software development industry. Full-stack developers proficient in MongoDB, Express.js, React.js, and Node.js are in high demand, offering excellent career prospects.
    </p>

    <h3 className='text-success'><i className="bi bi-check2-all"></i> Comprehensive Learning Experience</h3>

    <p>
        Our course provides a comprehensive learning experience, covering every aspect of the MERN stack. From databases to server-side development, dynamic user interfaces, and deployment, you'll gain a holistic understanding of full-stack web development.
    </p>

    <h3 className='text-success'><i className="bi bi-check2-all"></i> Hands-On Projects</h3>

    <p>
        Apply your newly acquired knowledge through hands-on projects, including the development of a complete MERN stack application. This practical approach ensures that you can confidently apply your skills in real-world scenarios.
    </p>

    <h3 className='text-success'><i className="bi bi-check2-all"></i> Expert Instruction</h3>

    <p>
        Learn from industry experts with real-world experience in MERN stack development. Benefit from their insights, best practices, and guidance, ensuring you receive top-notch instruction throughout the course.
    </p>

    <h3 className='text-success'><i className="bi bi-check2-all"></i> Career Advancement Opportunities</h3>

    <p>
        Acquiring MERN stack development skills opens doors to diverse career opportunities. Whether you're aiming to become a full-stack developer, MERN stack specialist, or pursue related roles, this course provides the foundation for career growth.
    </p>

    <h3 className='text-success'><i className="bi bi-check2-all"></i> Networking Opportunities</h3>

    <p>
        Connect with instructors and fellow learners in a collaborative learning environment. Networking opportunities abound, providing a platform to share insights, collaborate on projects, and expand your professional network.
    </p>

    <h3 className='text-success'><i className="bi bi-check2-all"></i> Continuous Assessment and Certificate of Completion</h3>
        <p>Your progress will be continuously assessed through assignments, quizzes, and participation. Upon successful completion of the course, receive a certificate recognizing your proficiency in MERN stack web development.</p>

        <h3 className='text-success'><i className="bi bi-check2-all"></i> Free 3-Month Internship Opportunity</h3>
        <p>As a bonus, participants in this course are eligible for a free 3-month internship opportunity. Gain practical experience, apply your skills in a professional setting, and further enhance your resume with valuable work experience in the field of MERN stack web development.</p>

    <h3 className='text-success'><i className="bi bi-check2-all"></i> Recognition and Certification</h3>

    <p>
        Upon successful completion of the course, receive a certificate that validates your proficiency in MERN stack development. Enhance your resume and stand out in the competitive job market with recognized certification.
    </p>

    <h3 className='text-success'><i className="bi bi-check2-all"></i> Flexible Learning Schedule</h3>

    <p>
        Our weekend classes, with 4 hours per day, allow you to balance learning with other commitments. This flexibility makes the course accessible to working professionals and students seeking to upskill.
    </p>
<br></br>
    <p>
        Embark on a transformative journey into the dynamic field of MERN stack development. Enroll today and unlock a world of opportunities in full-stack web development.

    </p>
    </>
  )
}

export default Perks