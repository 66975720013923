import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    return (
        <>
        <Helmet>
      <title>Privacy Policy - HSR Skills Lab</title>
      <meta name="description" content="Review the privacy policy of HSR Skills Lab. Understand how we collect, use, and protect your personal information. Your privacy matters, and we are committed to safeguarding it." />
    </Helmet>
            <Container className='py-5'>
                <Row className='justify-content-center'>
                    <Col md={8} lg={8} sm={12} >
                        <div className="paper p-4 shadow">
                            <div className="pin">
                                <div className="shadow"></div>
                                <div className="metal"></div>
                                <div className="bottom-circle"></div>
                            </div>
                            <div>
                                <div className='hsr-section-heading my-5'>
                                    <h1>Privacy Policy</h1>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <span className='hsr-divider'></span>
                                        <i className='bi bi-laptop mx-3 hsr-separator'></i>
                                        <span className='hsr-divider'></span>
                                    </div>
                                </div>
                                <div className='my-5'>
                                    <p><b>Last Updated: December 18, 2023</b></p>

                                    <p>Thank you for choosing HSR Skills Lab, a unit of HSR Hi-Tech Solutions for Skill Development. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information. By accessing our website or using our services, you agree to the terms outlined in this policy.</p>

                                    <ol className='mt-4'>
                                        <li>
                                            <p><b>Information We Collect:</b></p>
                                            <ol type='1'>
                                                <li>
                                                    <p><b>Personal Information:</b></p>
                                                    <p>We may collect personal information, including but not limited to, names, email addresses, contact numbers, and demographic information when you register for our skill development programs or interact with our website.</p>
                                                </li>
                                                <li>
                                                    <p><b>Usage Data:</b></p>
                                                    <p>We may collect information on how you interact with our website and services, including IP addresses, browser types, and pages visited. This data is used to improve the user experience and for analytics purposes.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p><b>How We Use Your Information:</b></p>
                                            <ol type="1">
                                                <li>
                                                    <p><b>Program Enrollment:</b></p>
                                                    <p>Your personal information is used for program enrollment, communication, and to provide you with relevant educational content.</p>
                                                </li>
                                                <li>
                                                    <p><b>Communication:</b></p>
                                                    <p>We may use your contact information to send you updates, newsletters, and marketing communications related to our services. You can opt-out of these communications at any time.</p>
                                                </li>
                                                <li>
                                                    <p><b>Analytics:</b></p>
                                                    <p>We use data analytics to improve our website, services, and user experience. This may involve third-party analytics services, but your personal information is not disclosed to these third parties.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <p><b>Data Security:</b></p>
                                            <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                                        </li>
                                        <li>
                                            <p><b>Third-Party Links:</b></p>

                                            <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review the privacy policies of any third-party websites you visit.</p>
                                        </li>
                                        <li>
                                            <p><b>Cookies:</b></p>
                                            <p>We use cookies and similar tracking technologies to enhance your experience on our website. You can modify your browser settings to disable cookies, but this may affect the functionality of our site.</p>
                                        </li>
                                        <li>
                                            <p><b>Your Rights:</b></p>
                                            <p>You have the right to access, correct, or delete your personal information. If you have any questions or requests regarding your data, please contact us at <a href="mailto:info@hsrsolutions.co.in" target='_blank'>info@hsrsolutions.co.in</a>.</p>
                                        </li>
                                        <li>
                                            <p><b>Changes to Privacy Policy:</b></p>
                                            <p>We may update this Privacy Policy periodically. Any changes will be effective upon posting the revised policy on our website.</p>
                                        </li>
                                        <li>
                                            <p><b>Contact Us:</b></p>
                                            <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info@hsrsolutions.co.in" target='_blank'>info@hsrsolutions.co.in</a>.</p>
                                        </li>
                                    </ol>
                                    <p>By using our services, you acknowledge that you have read, understood, and agreed to the terms of this Privacy Policy.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PrivacyPolicy