import React from 'react'

const About = () => {
  return (
    <>
      <h2>About the Data Science and Analytics using Python Course</h2>
      <br></br>
      <p>Welcome to our Data Science and Analytics using Python course! This 3-4 months program is meticulously crafted for individuals eager to explore the dynamic field of data science and analytics using the powerful programming language Python. Throughout the course, you will delve into essential libraries such as NumPy, Pandas, and Matplotlib, and apply your skills to real-world scenarios through two capstone projects.</p>
      <br></br>
      <h2>Course Overview:</h2>
      <br></br>
      <p>This course is designed to provide a comprehensive understanding of the entire data science workflow, from data manipulation to insightful visualizations. The curriculum is structured over 3-4 months, with live classes scheduled on weekends, allowing flexibility for working professionals or individuals with busy schedules.</p>

      <p><b>Course Duration:</b> 3-4 Months</p>

      <p><b>Live Classes Schedule:</b> Weekends, 4 hours each day</p>
      <br></br>
      <h2>What You Will Learn:</h2>
      <br></br>
      <h3>Month 1: Introduction to Data Science and Python Basics</h3>

      <ul>
        <li>Understand the fundamentals of data science</li>
        <li>Set up a Python environment and grasp basic programming concepts</li>
        <li>Explore the power of NumPy for numerical operations and statistical analysis</li>
      </ul>
      <br></br>
      <h3>Month 2: Data Manipulation with Pandas</h3>

      <ul>
        <li>Learn to manipulate and clean data using Pandas</li>
        <li>Handle missing data and outliers effectively</li>
        <li>Advance your skills with Pandas for grouping, aggregation, and merging datasets</li>
      </ul>
      <br></br>
      <h3>Month 3: Data Visualization with Matplotlib</h3>

      <ul>
        <li>Master the art of effective data visualization</li>
        <li>Create various types of plots and charts using Matplotlib</li>
        <li>Build interactive visualizations and dashboards</li>
      </ul>
      <br></br>
      <h3>Month 4: Capstone Projects and Final Review</h3>

      <ul>
        <li>Apply your knowledge to solve real-world problems in Capstone Project 1</li>
        <li>Tackle a more complex data analysis project in Capstone Project 2</li>
        <li>Receive feedback, present your findings, and showcase your skills</li>
      </ul>
      <br></br>
      <h2>Assessment and Recognition:</h2>

      <p>Your progress will be assessed through continuous evaluations, assignments, quizzes, and active participation. Successful completion of the course will earn you a certificate, validating your expertise in data science and analytics using Python.</p>
      <br></br>
      <h2>Why Choose This Course?</h2>

      <ul>
        <li>Comprehensive coverage of data science essentials</li>
        <li>Hands-on experience with industry-standard Python libraries</li>
        <li>Flexible weekend classes for a balanced learning experience</li>
        <li>Real-world application through capstone projects</li>
        <li>Expert instructors with industry experience</li>
      </ul>
      <br></br>
      <h2>Embark on Your Data Science Journey Today!</h2>

      <p>Whether you are a beginner or a professional seeking to enhance your data science skills, this course is your gateway to success. Join us on this enriching journey and unlock the doors to a world of opportunities in data science and analytics.</p>

    </>
  )
}

export default About