import React from 'react'

const CourseContent = () => {
    return (
        <>
            <h2>Full-Stack Web Development Mastery Course</h2>
            <br></br>
            <p>
                Our 9-month Full-Stack Web Development Mastery Course is a comprehensive journey covering a wide array of technologies. Through weekend classes, each lasting 3 hours, you'll progress from front-end to back-end mastery, databases, and project development. This course is suitable for beginners and seasoned developers alike.
            </p>
            <br></br>
            <h2>What You Will Learn</h2>

            <p>
                Throughout the course, you'll delve into the following technologies and concepts:
            </p>
            <br></br>
            <ul>
                <h3>Week 1-2: HTML, CSS, JavaScript</h3>
                <br></br>
                <ul>
                    <li>Master the basics of web development with HTML, CSS, and JavaScript.</li>
                    <li>Create static web pages and implement interactivity using JavaScript.</li>
                </ul>
                <br></br>
                <h3>Week 3-4: JQuery, AJAX, Bootstrap</h3>
                <br></br>
                <ul>
                    <li>Explore jQuery for simplified DOM manipulation.</li>
                    <li>Understand AJAX for asynchronous data retrieval.</li>
                    <li>Implement responsive design using the Bootstrap framework.</li>
                </ul>
                <br></br>

                <h3>Week 5-6: PHP, MySQL</h3>
                <br></br>
                <ul>
                    <li>Learn server-side scripting with PHP.</li>
                    <li>Explore MySQL for database management and integration.</li>
                </ul>
                <br></br>

                <h3>Week 7-8: Python, Django</h3>
                <br></br>
                <ul>
                    <li>Dive into Python for versatile programming.</li>
                    <li>Build scalable web applications using the Django framework.</li>
                </ul>
                <br></br>

                <h3>Week 9-10: ReactJs, NodeJs, ExpressJs</h3>
                <br></br>
                <ul>
                    <li>Develop dynamic and interactive user interfaces with ReactJs.</li>
                    <li>Explore server-side development with NodeJs and ExpressJs.</li>
                </ul>
                <br></br>

                <h3>Week 11-12: MongoDB, Github, VS Code</h3>
                <br></br>
                <ul>
                    <li>Master NoSQL databases with MongoDB.</li>
                    <li>Learn version control using Git and GitHub.</li>
                    <li>Enhance coding efficiency with Visual Studio Code.</li>
                </ul>
                <br></br>

                <h3>Week 13-14: GA4, Microsoft Clarity</h3>
                <br></br>
                <ul>
                    <li>Implement Google Analytics 4 (GA4) for web analytics.</li>
                    <li>Explore user behavior analytics with Microsoft Clarity.</li>
                </ul>
                <br></br>

                <h3>Week 15-21: 3 Mini Projects</h3>
                <br></br>
                <ul>
                    <li>Apply acquired skills in three smaller-scale projects.</li>
                    <li>Focus on specific technologies and concepts in each mini project.</li>
                </ul>
                <br></br>

                <h3>Week 22-36: 3 Capstone Projects</h3>
                <br></br>
                <ul>
                    <li>Engage in three comprehensive capstone projects.</li>
                    <li>Combine knowledge across various technologies to build fully functional web applications.</li>
                </ul>
                <br></br>
            </ul>

           <h6>You can also view detailed syllabus here. <a href="https://drive.google.com/file/d/1tTMFzBTrXJppTnYgi59kUueOJIAB6nX-/view?usp=sharing" target="_blank">Click Here</a></h6>
<br></br>
            <p>
                <b>Note:</b> The schedule is subject to adjustments based on the pace of learning and participant feedback.

            </p>

        </>
    )
}

export default CourseContent