import React from 'react';
import './Disclaimer.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const Disclaimer = () => {
    return (
        <>
        <Helmet>
      <title>Disclaimer - HSR Skills Lab</title>
      <meta name="description" content="Read the disclaimer for HSR Skills Lab. Understand the terms and conditions regarding the use of our website and services. Your trust and satisfaction are our top priorities." />
    </Helmet>
            <Container className='py-5'>
                <Row className='justify-content-center'>
                    <Col md={8} lg={8} sm={12} >
                        <div className="paper p-4 shadow">
                            <div className="pin">
                                <div className="shadow"></div>
                                <div className="metal"></div>
                                <div className="bottom-circle"></div>
                            </div>
                            <div>
                                <div className='hsr-section-heading my-5'>
                                    <h1>Disclaimer</h1>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <span className='hsr-divider'></span>
                                        <i className='bi bi-laptop mx-3 hsr-separator'></i>
                                        <span className='hsr-divider'></span>
                                    </div>
                                </div>
                                <div className='my-5'>
                                    <p>Welcome to HSR Skills Lab, a unit of <a href="https://www.hsrsolutions.co.in/" target='_blank' className='text-dark'>HSR Hi-Tech Solutions</a> for Skill Development. By accessing and using our services, you agree to comply with and be bound by the following disclaimer. Please read this disclaimer carefully before using our website or participating in any of our skill development programs.</p>

                                    <ol className='mt-4'>
                                        <li>
                                            <p><b>Educational Purposes:</b></p>
                                            <p>HSR Skills Lab provides skill development programs and resources for educational purposes only. The content presented is intended to enhance knowledge and proficiency in specific areas of IT and Programming. It does not constitute professional advice or certification.</p>
                                        </li>
                                        <li>
                                            <p><b>Program Information:</b></p>
                                            <p>While we strive to provide accurate and up-to-date information in our skill development programs, we do not guarantee the completeness, accuracy, or reliability of any content. HSR Skills Lab reserves the right to modify, update, or remove content without prior notice.</p>
                                        </li>
                                        <li>
                                            <p><b>User Responsibilities:</b></p>
                                            <p>Users are responsible for their own actions and decisions when participating in skill development programs. HSR Skills Lab is not liable for any consequences resulting from the use of our services, including but not limited to career decisions, employment opportunities, or project outcomes.</p>
                                        </li>
                                        <li>
                                            <p><b>External Links:</b></p>
                                            <p>Our website may contain links to external websites for additional resources or information. HSR Skills Lab is not responsible for the content, accuracy, or reliability of these external sites. The inclusion of such links does not imply endorsement.</p>
                                        </li>
                                        <li>
                                            <p><b>Limitation of Liability:</b></p>
                                            <p>HSR Skills Lab, its employees, instructors, and affiliates shall not be held liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with the use of our services, even if advised of the possibility of such damages.</p>
                                        </li>
                                        <li>
                                            <p><b>Privacy Policy:</b></p>
                                            <p>Please refer to our Privacy Policy for information on how we collect, use, and protect your personal information.</p>
                                        </li>
                                        <li>
                                            <p><b>Changes to Disclaimer:</b></p>
                                            <p>HSR Skills Lab reserves the right to update or modify this disclaimer at any time without prior notice. It is your responsibility to review this disclaimer periodically for changes.</p>
                                        </li>
                                    </ol>
                                    <p>By using our services, you acknowledge that you have read, understood, and agreed to the terms and conditions outlined in this disclaimer.</p>
                                    <br></br>
                                    <p>If you have any questions or concerns regarding this disclaimer, please contact us at <a href="mailto:info@hsrsolutions.co.in" target='_blank'>info@hsrsolutions.co.in</a></p><br></br>
                                    <p>Last updated: December 18, 2023.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Disclaimer