import React, { useState, useRef } from 'react';
import './Contact.css';
import { Helmet } from 'react-helmet';
import { Col, Container, Row, Card, Form, Button } from 'react-bootstrap';

const Contact = () => {
  const name = useRef(null);
  const email = useRef(null);
  const mobile = useRef(null);
  const subject = useRef(null);
  const message = useRef(null);
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('Name', name.current.value);
    formData.append('Email', email.current.value);
    formData.append('Mobile', mobile.current.value);
    formData.append('Subject', subject.current.value);
    formData.append('Message', message.current.value);
   

    var url = "https://script.google.com/macros/s/AKfycby6Sr5-qzyZ-HLB4qT6Z8kCAe8oIHZ56RCb7BESzF4-jgC7rXDElOUtlWkhiPy9_h4REw/exec";
    const res = await fetch(url, {
      method: "POST",
      body: formData
    });
    if(res.ok){
      setSuccess(true);
    }else{
      setErr(false);
    }
  }

  return (
    <>
       <Helmet>
      <title>Contact Us - HSR Skills Lab</title>
      <meta name="description" content="Connect with HSR Skills Lab. Reach out for inquiries, feedback, or just to say hello. We value your communication and look forward to assisting you on your learning journey." />
    </Helmet>
      <Container fluid className='hsr-page-title'>
        <Container>
          <div className='hsr-section-heading py-5'>
            <h1 className='text-white'>Contact Us</h1>
            <div className='d-flex align-items-center justify-content-center'>
              <span className='hsr-divider bg-white'></span>
              <i className='bi bi-laptop mx-3 hsr-separator'></i>
              <span className='hsr-divider bg-white'></span>
            </div>
            <div className='text-center mt-3'>
              <a href="/" className='text-white'>Home</a>
              <span className='mx-2 text-offwhite'>/</span>
              <span className='text-offwhite'>Contact Us</span>
            </div>
          </div>
        </Container>
      </Container>
      <Container>
        <div className='my-5'>
          <Row className='gy-5'>
            <Col md={6} lg={6} xs={12}>
              <p>Thank you for your interest in HSR Skills Lab. If you have any inquiries, feedback, or if you'd like to connect with us, please feel free to reach out using the contact information below:</p>

              <ul type="none" className='mt-5'>
                <li className='mt-3'>
                  <a href="tel: +91 9102538091" target='_blank'><i className='bi bi-telephone me-2'></i> +91 9102538091</a>
                </li>
                <li className='mt-3'>
                  <a href="https://wa.me/+919102538091" target='_blank'><i className='bi bi-whatsapp me-2'></i> +91 9102538091</a>
                </li>
                <li className='mt-3'>
                  <a href="mailto: info@hsrsolutions.co.in" target='_blank'><i className='bi bi-envelope me-2'></i> info@hsrsolutions.co.in</a>
                </li>
              </ul>

              <p className='mt-5'>Follow Us:</p>

              <a href="https://www.facebook.com/hsrskillslab/" target='_blank'><i className='bi bi-facebook me-3'></i></a>
              <a href="https://www.instagram.com/hsrskillslab/" target='_blank'><i className='bi bi-instagram me-3'></i></a>
              <a href="https://in.linkedin.com/company/hsrskillslab/" target='_blank'><i className='bi bi-linkedin me-3'></i></a>
            </Col>
            <Col md={6} lg={6} xs={12}>
              <Card className="shadow border-0 rounded-4 p-4">

                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" >
                    <Form.Label>Full Name<span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="text" placeholder="" required ref={name} />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Email Address<span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="email" required ref={email} />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Phone Number<span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="text" minLength={10} maxLength={10} required ref={mobile} />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Subject<span className='text-danger'>*</span></Form.Label>
                    <Form.Control type="text" required ref={subject}  />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Message<span className='text-danger'>*</span></Form.Label>
                    <Form.Control as="textarea" rows={3} required ref={message} />
                  </Form.Group>
                  <div className='text-center mt-4'>
                    <Button type='submit'>Send Message</Button>
                  </div>
                </Form>

                <div className='pt-3'>
                  {success ? <div className='alert alert-success text-sm'>Your message sent successfully! Our team will contact you within 24 hours.</div> : ''}
                  {err ? <div className='alert alert-danger text-sm'>Sorry! Some error occurred. Please try again!</div> : ''}
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default Contact