import React from 'react'

const CourseContent = () => {
  return (
    <>
      <h2>Data Science and Analytics using Python Course Plan</h2>

      <p>Welcome to the Data Science and Analytics using Python course! This comprehensive 3-4 months program is designed for individuals interested in leveraging Python for data analysis. The course will cover essential libraries such as NumPy, Pandas, and Matplotlib, and includes two capstone projects to apply your skills in real-world scenarios.</p>
      <br></br>
      <h3>Month 1: Introduction to Data Science and Python Basics</h3>
      <br></br>
      <ul>
        <p><strong>Week 1-2:</strong> Introduction to Data Science and Python</p>
        <ul>
          <li>Understanding the data science workflow</li>
          <li>Setting up Python environment</li>
          <li>Basic Python programming concepts</li>
        </ul>
        <br></br>
        <p><strong>Week 3-4:</strong> Introduction to NumPy</p>
        <ul>
          <li>Introduction to arrays and matrices</li>
          <li>Numerical operations with NumPy</li>
          <li>Statistical analysis using NumPy</li>
        </ul>

      </ul>
      <br></br>
      <h3>Month 2: Data Manipulation with Pandas</h3>
      <br></br>
      <ul>
        <p><strong>Week 5-6:</strong> Introduction to Pandas</p>
        <ul>
          <li>Data structures in Pandas: Series, DataFrame</li>
          <li>Data cleaning and preprocessing with Pandas</li>
          <li>Handling missing data and outliers</li>
        </ul>
        <br></br>

        <p><strong>Week 7-8:</strong> Advanced Pandas Techniques </p>
        <ul>
          <li>Grouping and aggregation</li>
          <li>Merging and joining datasets</li>
          <li>Time-series analysis with Pandas</li>
        </ul>

      </ul>
      <br></br>
      <h3>Month 3: Data Visualization with Matplotlib</h3>
<br></br>
      <ul>
        <p><strong>Week 9-10:</strong> Introduction to Data Visualization</p>
          <ul>
            <li>Understanding the principles of effective data visualization</li>
            <li>Basic plotting with Matplotlib</li>
            <li>Customizing plots and charts</li>
          </ul>
        <br></br>

        <p><strong>Week 11-12:</strong> Advanced Data Visualization</p>
          <ul>
            <li>Creating interactive visualizations</li>
            <li>Using Seaborn for statistical data visualization</li>
            <li>Building dashboards with Matplotlib and Seaborn</li>
          </ul>
        
      </ul>
<br></br>
      <h3>Month 4: Capstone Projects and Final Review</h3>
<br></br>
      <ul>
        <p><strong>Week 13-14:</strong> Capstone Project 1</p>
          <ul>
            <li>Apply NumPy, Pandas, and Matplotlib to solve a real-world problem</li>
            <li>Receive feedback and guidance from instructors</li>
          </ul>
        <br></br>

        <p><strong>Week 15-16:</strong> Capstone Project 2</p>
          <ul>
            <li>Develop a more complex data analysis project</li>
            <li>Present your findings and solutions</li>
          </ul>
        <br></br>

        <p><strong>Week 17-18:</strong> Final Review and Q&A</p>
          <ul>
            <li>Review key concepts and techniques</li>
            <li>Open session for student questions</li>
          </ul>
        
      </ul>
<br></br>
      <p><strong>Assessment:</strong> Continuous assessment through assignments, quizzes, and participation. Capstone projects will be evaluated for practical application and problem-solving skills.</p>

      <p><strong>Note:</strong> This course provides a comprehensive foundation for individuals interested in pursuing a career in data science and analytics using Python. It combines theoretical knowledge with practical application through hands-on projects.</p>
    </>
  )
}

export default CourseContent