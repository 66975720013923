import React from 'react';
import './Cta.css';
import { Container } from 'react-bootstrap';
import OrangeButton from '../OrangeButton/OrangeButton';

const Cta = () => {
  return (
    <>
    <Container fluid className='hsr-cta-section py-5'>
        <Container>
            <h1>Not Sure Where To Start?</h1>
            <div className='text-center mt-4'>
            <OrangeButton text={'Contact Us'} action={()=>window.location.href = '/contact'} />
            </div> 
        </Container>
    </Container>
    </>
  )
}

export default Cta