import React from 'react';
import './FAQs.css';
import { Helmet } from 'react-helmet';
import { Col, Container, Row, Accordion, AccordionItem } from 'react-bootstrap';

const FAQs = () => {
  const faqs = [
    {
      "id": "1",
      "question": "What is HSR Skills Lab?",
      "answer": "HSR Skills Lab is a specialized unit of HSR Hi-Tech Solutions for Skill Development, focused on providing comprehensive skill development programs in the fields of IT and Programming. We aim to empower individuals with the knowledge and practical skills necessary for success in the dynamic tech industry."
    },
    {
      "id": "2",
      "question": "What programs does HSR Skills Lab offer?",
      "answer": "We offer a diverse range of skill development programs covering areas such as Full Stack Development, Data Science, Machine Learning, and more. Explore our Programs page for detailed information on each course."
    },
    {
      "id": "3",
      "question": "Who are the instructors at HSR Skills Lab?",
      "answer": "Our instructors are industry experts and seasoned professionals with hands-on experience in their respective fields. They bring real-world insights to the learning experience, ensuring that our students receive practical and relevant knowledge."
    },
    {
      "id": "4",
      "question": "Is there a hands-on component to the programs?",
      "answer": "Yes, we believe in learning by doing. Our programs incorporate hands-on projects and practical exercises to provide students with real-world experience and enhance their problem-solving skills."
    },
    {
      "id": "5",
      "question": "How often are the programs updated?",
      "answer": "Our curriculum is regularly updated to align with the latest trends and technologies in the IT and Programming industries. We ensure that our students receive education that is relevant and in tune with the rapidly evolving tech landscape."
    },
    {
      "id": "6",
      "question": "How can I enroll in a program/course?",
      "answer": "To enroll in a program, visit our Programs/Courses page, choose the course that aligns with your goals, and follow the enrollment instructions. For further assistance, feel free to reach out to us at <a href='mailto: info@hrsolutions.co.in' target='_blank'>info@hsrsolutions.co.in</a>."
    },
    {
      "id": "7",
      "question": "What are the eligibility criteria for the programs?",
      "answer": "Eligibility criteria vary for each program. Check the specific program page for details on prerequisites and requirements. In general, our programs cater to a wide range of skill levels, from beginners to experienced professionals."
    },
    {
      "id": "8",
      "question": "Is financial assistance available for programs?",
      "answer": "We understand the importance of accessibility. While we don't currently offer financial assistance, we are continually exploring options to make our programs accessible to a broader audience."
    },
    {
      "id": "9",
      "question": "How can I get in touch with HSR Skills Lab for additional information?",
      "answer": "For any inquiries or additional information, you can contact us at <a href='mailto: info@hrsolutions.co.in' target='_blank'>info@hsrsolutions.co.in</a>. We're here to help and answer any questions you may have."
    },
    {
      "id": "10",
      "question": "How does HSR Skills Lab support career development?",
      "answer": "In addition to providing education, we offer career guidance services to help you prepare for the industry. From building a strong portfolio to interview preparation, we are committed to supporting your career growth."
    }
  ]

  return (
    <>
      <Helmet>
      <title>FAQs - HSR Skills Lab</title>
      <meta name="description" content="Explore frequently asked questions about HSR Skills Lab. Find answers to queries related to courses, enrollment, certifications, and more. Your journey to success starts here!" />
    </Helmet>
      <Container fluid className='hsr-page-title'>
        <Container>
          <div className='hsr-section-heading py-5'>
            <h1 className='text-white'>Frequently Asked Questions</h1>
            <div className='d-flex align-items-center justify-content-center'>
              <span className='hsr-divider bg-white'></span>
              <i className='bi bi-laptop mx-3 hsr-separator'></i>
              <span className='hsr-divider bg-white'></span>
            </div>
            <div className='text-center mt-3'>
              <a href="/" className='text-white'>Home</a>
              <span className='mx-2 text-offwhite'>/</span>
              <span className='text-offwhite'>FAQs</span>
            </div>
          </div>
        </Container>
      </Container>
      <Container>
        <div className='my-5'>
          <Row className='justify-content-center'>
            <Col md={8} lg={8} sm={12}>
              <Accordion>
                {faqs.map((d) => {
                  return (
                    <Accordion.Item className='rounded-0 border-0 my-3 shadow-sm' eventKey={d.id}>
                    <Accordion.Header>{d.question}</Accordion.Header>
                    <Accordion.Body>
                      {d.answer}
                    </Accordion.Body>
                  </Accordion.Item>
                  )
                })}
              </Accordion>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default FAQs