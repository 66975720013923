import React from 'react';
import './WhyChooseUs.css';
import { Card, Col, Container, Row } from 'react-bootstrap';

const WhyChooseUs = () => {
    return (
        <>
            <section className="py-5">
                <Container>
                    <div className='hsr-section-heading my-5'>
                        <h1>Why Choose Us?</h1>
                        <div className='d-flex align-items-center justify-content-center'>
                            <span className='hsr-divider'></span>
                            <i className='bi bi-laptop mx-3 hsr-separator'></i>
                            <span className='hsr-divider'></span>
                        </div>
                    </div>
                    <Row className='gy-5 mb-5'>
                        <Col lg={4} md={4} sm={12}>
                            <Card className='border-0 rounded-4 shadow p-3 hsr-wcu-card'>
                                <i className='bi bi-laptop-fill text-center'></i>
                                <h4 className='text-center'>Live Interactive Classes</h4>
                                <p className='text-sm'>Experience engaging live classes that go beyond pre-recorded content. Our dynamic instructors conduct real-time sessions, fostering interactive discussions, Q&A, and collaborative learning. Immerse yourself in a classroom environment from the comfort of your own space.</p>
                            </Card>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <Card className='border-0 rounded-4 shadow p-3 hsr-wcu-card'>
                                <i className='bi bi-file-person text-center'></i>
                                <h4 className='text-center'>24x7 Mentorship</h4>
                                <p className='text-sm'>Enjoy continuous support with our round-the-clock mentorship program. Whether you have a question about a challenging concept or need guidance on a project, our mentors are available 24 hours a day, 7 days a week to provide assistance, ensuring you never feel alone on your learning journey.</p>
                            </Card>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <Card className='border-0 rounded-4 shadow p-3 hsr-wcu-card'>
                                <i className='bi bi-tools text-center'></i>
                                <h4 className='text-center'>Practical Learning Approach</h4>
                                <p className='text-sm'>We prioritize hands-on, practical learning experiences. Our curriculum is designed to equip you with applicable skills, and our live classes often include coding exercises, real-world projects, and demonstrations to reinforce theoretical concepts. We always follow "learn by doing" approach.</p>
                            </Card>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <Card className='border-0 rounded-4 shadow p-3 hsr-wcu-card'>
                                <i className='bi bi-person-workspace text-center'></i>
                                <h4 className='text-center'>Industry-Exp Instructors</h4>
                                <p className='text-sm'>Learn from instructors with extensive industry experience. Our teaching team comprises professionals who have worked in the IT field, bringing real-world insights into the classroom. Benefit from their practical knowledge and stay updated on industry best practices.</p>
                            </Card>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <Card className='border-0 rounded-4 shadow p-3 hsr-wcu-card'>
                                <i className='bi bi-clock text-center'></i>
                                <h4 className='text-center'>Flexible Learning Schedule</h4>
                                <p className='text-sm'>Tailor your learning experience to fit your schedule. With a variety of live class timings and recorded sessions for review, our flexible approach allows you to balance your skill development with other commitments, making it accessible for working professionals and students alike.</p>
                            </Card>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <Card className='border-0 rounded-4 shadow p-3 hsr-wcu-card'>
                                <i className='bi bi-briefcase text-center'></i>
                                <h4 className='text-center'>Career-Ready Courses</h4>
                                <p className='text-sm'>Our courses are meticulously designed to impart skills that are immediately applicable in the professional world. From industry-relevant projects to the latest technologies, we prepare you for success in your career by ensuring that you acquire the skills demanded by today's employers.</p>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default WhyChooseUs