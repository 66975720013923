import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const RefundPolicy = () => {
    return (
        <>
        <Helmet>
      <title>Refund & Cancellation - HSR Skills Lab</title>
      <meta name="description" content="Understand the refund and cancellation policies at HSR Skills Lab. Know your rights and options in case you need to make changes to your enrollment. Your satisfaction is our priority." />
    </Helmet>
            <Container className='py-5'>
                <Row className='justify-content-center'>
                    <Col md={8} lg={8} sm={12} >
                        <div className="paper p-4 shadow">
                            <div className="pin">
                                <div className="shadow"></div>
                                <div className="metal"></div>
                                <div className="bottom-circle"></div>
                            </div>
                            <div>
                                <div className='hsr-section-heading my-5'>
                                    <h1>Refund & Cancellation</h1>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <span className='hsr-divider'></span>
                                        <i className='bi bi-laptop mx-3 hsr-separator'></i>
                                        <span className='hsr-divider'></span>
                                    </div>
                                </div>
                                <div className='my-5'>
                                    <p><b>Last Updated: December 18, 2023</b></p>

                                    <p>Welcome to HSR Skills Lab, a unit of <a href="https://www.hsrsolutions.co.in/" target='_blank' className='text-dark'>HSR Hi-Tech Solutions</a> for Skill Development. This Refund and Cancellation Policy outlines the terms and conditions regarding refunds and cancellations for our skill development programs. By enrolling in our programs, you agree to comply with this policy.</p>

                                    <p><b>1. Refund Policy:</b></p>

                                    <p><b>1.1 Eligibility for Refunds:</b></p>
                                    <p>Refunds are available to participants who meet the criteria outlined below:</p>

                                    <p>Request for a refund is made within 7 days of the program start date.
                                        The participant has not completed more than 7 days from the start of the program.</p>
                                    <p><b>1.2 Refund Process:</b></p>
                                    <p>To request a refund, participants must submit a written request to <a href="mailto:info@hsrsolutions.co.in" target='_blank'>info@hsrsolutions.co.in</a> within the specified timeframe. Refund requests will be processed within 3 days of receipt.</p>

                                    <p><b>1.3 Refund Amount:</b></p>
                                    <p>The refund amount will be determined based on the following:</p>

                                    <ul>
                                        <li><p>Full refund if the request is made within 7 days before the program start date.
                                            60% refund if the request is made within the first 7 days of the program.</p>
                                        </li>
                                        <li>
                                            <p>No refund if the request is made after completing more than 7 days from start of the program.</p>
                                        </li>
                                    </ul>
                                    <p><b>2. Cancellation Policy:</b></p>

                                    <p><b>2.1 Cancellation by Participants:</b></p>
                                    <p>Participants may cancel their enrollment in a program by providing written notice to <a href="mailto:info@hsrsolutions.co.in" target='_blank'>info@hsrsolutions.co.in</a>. The following conditions apply:</p>

                                    <ul>
                                        <li>
                                            Full refund if the cancellation is made within 7 days before the program start date.

                                        </li>
                                        <li>
                                            60% refund if the cancellation is made within the first 7 days of the program.

                                        </li>
                                        <li>
                                            No refund if the cancellation is made after completing more than 7 days from start of the program.
                                        </li>
                                    </ul>
                                    <p><b>2.2 Cancellation by HSR Skills Lab:</b></p>
                                    <p>HSR Skills Lab reserves the right to cancel a program due to unforeseen circumstances, insufficient enrollment, or other reasons. In such cases, participants will be notified, and a full refund will be provided.</p>

                                    <p><b>3. Transfer of Enrollment:</b></p>

                                    <p><b>3.1 Transfer Request:</b></p>
                                    <p>Participants may request to transfer their enrollment to another program within 7 days before the start date of the original program.</p>

                                    <p><b>3.2 Transfer Process:</b></p>
                                    <p>Transfer requests should be submitted in writing to <a href="mailto:info@hsrsolutions.co.in" target='_blank'>info@hsrsolutions.co.in</a>. Transfers are subject to availability and approval.</p>

                                    <p><b>4. Contact Us:</b></p>

                                    <p>If you have any questions or concerns about our Refund and Cancellation Policy, please contact us at <a href="mailto:info@hsrsolutions.co.in" target='_blank'>info@hsrsolutions.co.in</a>.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default RefundPolicy