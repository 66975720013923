import React from 'react';
import './NavBar.css';
import { Container, Navbar, Nav } from 'react-bootstrap';
import logo from '../../assets/images/top.png';
import OrangeButton from '../../components/OrangeButton/OrangeButton';
import { redirect } from "react-router-dom";

export const NavBar = () => {

  return (
    <>
      <Navbar expand="lg" className="bg-white sticky-top">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} height={60} alt="HSR Hi-Tech Solutions Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className='border-0' />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/courses">Our Courses</Nav.Link>
              {/* <Nav.Link href="/blogs">Blogs</Nav.Link> */}
              <Nav.Link href="/faqs">FAQs</Nav.Link>
              <Nav.Link href="/contact">Contact Us</Nav.Link>
            </Nav>
            <OrangeButton text={<><i className='bi bi-whatsapp me-1'></i> Enquire Now</>} action={()=>window.location.href = 'https://wa.me/+919102538091'} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
